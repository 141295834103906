import { getConfig } from "@src/config";
import { Maybe } from "@src/graphql.apollo.generated";
import { getCurrencySignFromDomain } from "@src/skeleton/intlProvider/CustomIntlProvider";
import { IntlShape } from "react-intl";

interface FormattedCurrencyProps {
    maximumFractionDigits?: number;
    value?: Maybe<number>;
}

export function getFormattedCurrency({ value, maximumFractionDigits = 0 }: FormattedCurrencyProps, intl: IntlShape) {
    return `${getCurrencySignFromDomain(getConfig("scope").domain)} ${value ? intl.formatNumber(value, { maximumFractionDigits }) : "-"}`;
}

import * as React from "react";
import ReactDOM from "react-dom";
import styled, { createGlobalStyle } from "styled-components";

import { InfoIconText } from "./InfoIcon.sc";

const BodyStyle = createGlobalStyle<{ isOpen: boolean }>`
    && body {
        ${({ isOpen }) => isOpen && "overflow: hidden"}
    }
`;

interface InfoIconPortalProps {
    infoText: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
}

export const InfoIconPortal = ({ infoText, isOpen, onClose }: InfoIconPortalProps) => {
    if (!isOpen) return null;
    return ReactDOM.createPortal(
        <InfoIconWrapper id="infoIconPortal">
            <BodyStyle isOpen={isOpen} />
            <BackdropHandler
                onClick={(e) => {
                    e.stopPropagation();
                    onClose();
                }}
            />
            <InfoIconText>{infoText}</InfoIconText>
        </InfoIconWrapper>,
        document.body,
    );
};

const BackdropHandler = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2);
`;

const InfoIconWrapper = styled.div`
    position: fixed;
    z-index: 15;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
`;

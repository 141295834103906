import theme from "@src/shared/theme/theme-porschebank";
import { OptionTypeBase, Styles } from "react-select";

export const DefaultReactSelectStyle = {
    control: (provided: any, state: any) => ({
        ...provided,
        minHeight: state.selectProps.isSmall ? 32 : state.selectProps.isLightbox ? 40 : 50,
        borderRadius: "1px",
        borderColor: theme.colors.borderLightGray,
        boxShadow: "none",
        minWidth: state.selectProps.isSmall ? "auto" : "75px",
        flexWrap: "nowrap",
    }),
    dropdownIndicator: (provided: any, state: any) => {
        return {
            ...provided,
            display: state.hasValue ? "none" : "inherit",
            margin: 0,
            padding: 0,
            backgroundImage: `linear-gradient(45deg, ${theme.colors.porscheBankBlue} 50%, #FFFFFF 50%)`,
            width: "6px",
            height: "6px",
            transform: "rotate(-45deg) translateY(-3px)",
            svg: {
                display: "none",
            },
        };
    },
    indicatorsContainer: (provided: any, state: any) => ({
        ...provided,
        paddingRight: state.selectProps.isSmall ? "10px" : "5px",
        paddingLeft: 0,
        div: {
            display: "inherit",
        },
    }),
    indicatorSeparator: () => ({
        display: "none",
    }),
    clearIndicator: () => ({
        marginRight: "-4px",
        marginTop: "4px",
        color: theme.colors.porscheBankBlue,
        background: "#fff",
        zIndex: 1,
    }),
    menu: (provided: any) => ({
        ...provided,
        zIndex: 2,
        textAlign: "left",
        color: theme.colors.porscheBankBlue,
        border: `1px solid ${theme.colors.borderLightGray}`,
        borderRadius: "2px",
    }),
    valueContainer: (provided: any, state: any) => ({
        flexDirection: "row",
        flexWrap: "nowrap",
        display: "flex",
        flex: 1,
        padding: state.selectProps.isLightbox ? "2px 15px" : "2px 8px",
        position: "initial",
        height: "100%",
        color: theme.colors.porscheBankBlue,
        fontSize: state.selectProps.isSmall || state.selectProps.isLightbox ? "14px" : "16px",
        overflow: "hidden",
    }),
    singleValue: () => ({
        color: theme.colors.porscheBankBlue,
    }),
    multiValue: (provided: any) => ({
        ...provided,
        borderRadius: 16,
        padding: 3,
        maxWidth: 195,
    }),
    input: (provided: any) => ({
        ...provided,
        padding: 0,
        margin: 0,
        display: "flex",
        alignItems: "center",
    }),
    placeholder: (provided: any) => ({
        ...provided,
        color: theme.colors.porscheBankBlue,
        opacity: 0.7,
    }),
} as Styles<OptionTypeBase, boolean>;

export const DarkReactSelectStyle = {
    control: (provided: any) => ({
        ...provided,
        height: "30px",
        minHeight: "30px",
        background: "none",
        border: "none",
        boxShadow: "none",
    }),
    dropdownIndicator: (provided: any) => {
        return {
            ...provided,
            padding: 0,
            backgroundImage: "linear-gradient(45deg, #FFFFFF 50%, transparent 50%)",
            width: "8px",
            height: "8px",
            transform: "rotate(-45deg) translateY(-3px)",
            svg: {
                display: "none",
            },
            "@media all and (min-width: 768px)": {
                margin: "-26px 0 0 0",
            },
        };
    },
    indicatorSeparator: () => ({
        display: "none",
    }),
    singleValue: () => ({
        color: theme.colors.white,
    }),
    menu: (provided: any) => ({
        ...provided,
        color: theme.colors.black,
        borderRadius: "2px",
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        fontSize: "18px",
        fontWeight: "bold",
        padding: 0,
    }),
};

export const SortReactSelectStyle = {
    control: (provided: any) => ({
        ...provided,
        height: "50px",
        border: "none",
        boxShadow: "none",
        background: "transparent",
        width: "100%",
    }),
    dropdownIndicator: (provided: any) => {
        return {
            ...provided,
            display: "inherit",
            margin: 0,
            padding: 0,
            backgroundImage: `linear-gradient(45deg, ${theme.colors.porscheBankBlue} 50%, transparent 50%)`,
            width: "6px",
            height: "6px",
            transform: "rotate(-45deg) translateY(-3px)",
            svg: {
                display: "none",
            },
        };
    },
    indicatorsContainer: (provided: any) => ({
        ...provided,
        paddingLeft: "15px",
    }),
    indicatorSeparator: () => ({
        display: "none",
    }),
    clearIndicator: () => ({
        marginRight: "-4px",
        marginTop: "4px",
        color: theme.colors.porscheBankBlue,
        background: "#fff",
        zIndex: 1,
    }),
    singleValue: (provided: any) => ({
        ...provided,
        lineHeight: "40px",
        color: theme.colors.porscheBankBlue,
        fontFamily: theme.fonts.markW1G,
        padding: "0 5px",
    }),
    menu: (provided: any) => ({
        ...provided,
        width: "250px",
        marginLeft: "50%",
        marginTop: 0,
        transform: "translateX(-50%)",
        boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
        borderRadius: "2px",
        ":before": {
            content: '" "',
            display: "block",
            width: "16px",
            height: "16px",
            position: "absolute",
            top: "-2px",
            left: "50%",
            transform: "rotate(45deg) translateX(-50%)",
            background: "#ffffff",
            borderTop: "1px solid #e6e6e6",
            borderLeft: "1px solid #e6e6e6",
        },
    }),
    menuList: () => ({
        border: "1px solid #e6e6e6",
        "div:active": {
            background: "#ffffff",
            color: theme.colors.porscheBankBlue,
        },
        "div:nth-child(even)": {
            borderBottom: "1px solid #e6e6e6",
            padding: "5px 20px 15px 20px",
        },
        "div:nth-child(odd)": {
            padding: "15px 20px 5px 20px",
        },
    }),
    option: (provided: any, { isDisabled, isSelected }: any) => {
        return {
            ...provided,
            backgroundColor: "#ffffff",
            color: isDisabled ? "#cccccc" : isSelected ? theme.colors.porscheBankRed : theme.colors.porscheBankBlue,
            cursor: isDisabled ? "not-allowed" : "pointer",
            ":hover": {
                color: isDisabled ? "#cccccc" : theme.colors.porscheBankRed,
            },
        };
    },
    valueContainer: (provided: any) => ({
        ...provided,
        justifyContent: "flex-end",
    }),
};

export const selectStyles = {
    default: DefaultReactSelectStyle,
    dark: DarkReactSelectStyle,
    sort: SortReactSelectStyle,
};

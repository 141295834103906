import * as React from "react";

import { DesktopContainer, InfoIconContainer, InfoIconIcon, InfoIconText, MobileContainer, Root } from "./InfoIcon.sc";
import { InfoIconPortal } from "./InfoIconPortal";

interface InfoIconProps {
    infoText?: React.ReactNode;
    alignment?: "left" | "right" | "center";
    color?: "white" | "porschebankBlue";
    isClickable?: boolean;
}

const InfoIcon: React.FunctionComponent<InfoIconProps> = ({ infoText, color = "porschebankBlue", alignment = "left", isClickable = false }) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [isDesktopOpen, setIsDesktopOpen] = React.useState<boolean>(false);

    const toggleOpenInfo = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (isClickable) {
            e.preventDefault();
            setIsDesktopOpen(!isDesktopOpen);
        }
    };

    return (
        <Root>
            <DesktopContainer>
                <InfoIconContainer onClick={toggleOpenInfo}>
                    <InfoIconIcon color={color}>i</InfoIconIcon>
                </InfoIconContainer>
                <InfoIconText alignment={alignment} isOpen={isDesktopOpen}>
                    {infoText}
                </InfoIconText>
            </DesktopContainer>
            <MobileContainer>
                <InfoIconContainer
                    onClick={(e) => {
                        e.preventDefault();
                        setIsOpen(true);
                    }}
                >
                    <InfoIconIcon color={color}>i</InfoIconIcon>
                </InfoIconContainer>
                <InfoIconPortal infoText={infoText} isOpen={isOpen} onClose={() => setIsOpen(false)} />
            </MobileContainer>
        </Root>
    );
};

export default InfoIcon;

import * as React from "react";

import * as sc from "./Image.sc";

interface ImageProps {
    alt?: string;
    loadLazy?: boolean;
    src?: string;
    width?: number;
    height?: number;
    className?: string;
    defaultLoading?: boolean;
}

const Image: React.FunctionComponent<ImageProps> = ({ alt, loadLazy, src, width, height, className, defaultLoading = true }) => {
    const [isLoading, setIsLoading] = React.useState(defaultLoading);

    const handleOnLoad = () => {
        setIsLoading(false);
    };

    const handleOnError = () => {
        setIsLoading(false);
    };

    const hasSizeInParams = src?.includes("?size");

    return (
        <sc.Root width={width} height={height} className={className} isLoading={isLoading}>
            {loadLazy ? (
                <sc.ImageLazy
                    data-src={src ? (hasSizeInParams ? src : replaceImageSize(src)) : undefined}
                    alt={alt}
                    width={width ? `${width}px` : "100%"}
                    height={height ? `${height}px` : "100%"}
                    onLoad={handleOnLoad}
                    onError={handleOnError}
                />
            ) : (
                <sc.Image
                    src={src}
                    alt={alt}
                    width={width ? `${width}px` : "100%"}
                    height={height ? `${height}px` : "100%"}
                    onLoad={handleOnLoad}
                    onError={handleOnError}
                />
            )}
        </sc.Root>
    );
};

// this function replace the current image size to 320px so that less data is loaded
const replaceImageSize = (url: string) => url.replace(/\/\d{3,4}$/, "/320");

export default Image;

import useSearchResult from "@src/shared/context/searchresult/useSearchResult";
import getFormattedNumber from "@src/shared/utils/getFormattedNumber";
import * as React from "react";
import { useIntl } from "react-intl";
import ReactSelect from "react-select";

import { ReactSelectOptions } from "../../../../../types";
import { ButtonOption, ButtonsContainer, SelectContainer } from "../ChoiceFilter.sc";
import { selectStyles } from "../ReactSelectStyle";

interface ISingleChoiceFilter<T> {
    value?: T;
    options: ReactSelectOptions<T>[];
    onChange?: (value: T | null) => void;
    mobileVariant?: "buttons" | "select";
    placeholder?: string;
    isSearchable?: boolean;
    isClearable?: boolean;
    isDisabled?: boolean;
    variant?: "default" | "dark" | "sort";
    iconLabel?: boolean;
    selectAll?: boolean;
    isSmall?: boolean;
}

const SingleChoiceFilter = <T extends any>({
    value,
    options,
    onChange,
    mobileVariant = "select",
    placeholder,
    variant = "default",
    isSearchable = false,
    isClearable = false,
    isDisabled = false,
    iconLabel = false,
    selectAll = false,
    isSmall,
}: ISingleChoiceFilter<T>) => {
    const searchResult = useSearchResult();
    const intl = useIntl();

    //This is because the lack of support of SSR in react-select
    const [currentValue, setCurrentValue] = React.useState<ReactSelectOptions<T> | undefined>(undefined);

    React.useEffect(() => {
        setCurrentValue(valueForOptions(options, value));
    }, [value, options]);

    const handleClick = (option: ReactSelectOptions<T>) => {
        handleSelectChange(option);
    };

    const handleSelectChange = (option?: ReactSelectOptions<T>) => {
        if (onChange) {
            onChange(option?.value ?? null);
        }
    };

    return (
        <div>
            <ButtonsContainer isVisibleMobile={mobileVariant === "buttons"}>
                {selectAll && (
                    <ButtonOption onClick={() => handleSelectChange(undefined)} selected={!value}>{`Alle (${getFormattedNumber(
                        searchResult.totalCount,
                    )})`}</ButtonOption>
                )}
                {options.map((option) => (
                    <ButtonOption key={option.label} selected={currentValue === option} onClick={() => handleClick(option)} iconLabel={iconLabel}>
                        {iconLabel ? (
                            <img
                                className="lazyload"
                                data-src={`/brands/${option.value}.png`}
                                width="47"
                                height="47"
                                alt={`${intl.formatMessage({
                                    id: "leaseme.logos.label",
                                    defaultMessage: "Markenlogo",
                                })} ${option.label}`}
                            />
                        ) : (
                            option.label
                        )}
                    </ButtonOption>
                ))}
            </ButtonsContainer>
            <SelectContainer isVisibleMobile={mobileVariant === "select"}>
                <ReactSelect
                    value={currentValue ?? null}
                    styles={selectStyles[variant]}
                    options={options}
                    onChange={handleSelectChange}
                    placeholder={placeholder}
                    isSearchable={isSearchable}
                    isClearable={isClearable}
                    isDisabled={isDisabled}
                    noOptionsMessage={() =>
                        intl.formatMessage({
                            id: "leasme.filter.noOption",
                            defaultMessage: "Keine Optionen",
                        })
                    }
                    isSmall={isSmall}
                />
            </SelectContainer>
        </div>
    );
};

export default SingleChoiceFilter;

function valueForOptions<T>(options: ReactSelectOptions<T>[], value?: T) {
    return options.find((option) => JSON.stringify(option.value) === JSON.stringify(value));
}

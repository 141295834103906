import styled, { css } from "styled-components";

interface IButtonsContainerProps {
    isVisibleMobile: boolean;
}

export const ButtonsContainer = styled.div<IButtonsContainerProps>`
    display: ${({ isVisibleMobile }) => (isVisibleMobile ? "flex" : "none")};
    white-space: nowrap;
    overflow-x: auto;
    padding: 6px 0;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        display: none;
    }
`;

interface IButtonOptionProps {
    selected: boolean;
    iconLabel?: boolean;
}

export const ButtonOption = styled.button<IButtonOptionProps>`
    display: inline-block;
    padding: ${({ iconLabel }) => (iconLabel ? 0 : "15px 20px")};
    background: 0;
    outline: 0;
    border: 1px solid ${({ theme, selected }) => (selected ? theme.colors.porscheBankBlue : theme.colors.borderGray)};
    border-radius: 2px;
    font-weight: 600;
    color: ${({ theme, selected }) => (selected ? theme.colors.porscheBankBlue : theme.colors.porscheBankBlue)};
    width: fit-content;
    font-family: ${({ theme }) => theme.fonts.roboto};

    ${({ selected }) =>
        selected &&
        css`
            box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.07);
        `}

    &:not(:first-child) {
        margin-left: 5px;
    }
`;

interface ISelectContainerProps {
    isVisibleMobile: boolean;
}

export const SelectContainer = styled.div<ISelectContainerProps>`
    display: ${({ isVisibleMobile }) => (isVisibleMobile ? "block" : "none")};
    font-family: ${({ theme }) => theme.fonts.roboto};

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        display: block;
    }
`;

import styled, { css, keyframes } from "styled-components";

const loadingAnimation = keyframes`
    to {
        background-position: 600% 0;
    }
`;

interface RootProps {
    width?: number;
    height?: number;
    isLoading?: boolean;
}

export const Root = styled.div<RootProps>`
    width: ${({ width }) => (width ? `${width}px` : "100%")};
    height: ${({ height }) => (height ? `${height}px` : "100%")};

    ${({ isLoading }) =>
        isLoading &&
        css`
            background-color: ${({ theme }) => theme.colors.lightGray};
            background-image: linear-gradient(90deg, transparent 0, ${({ theme }) => theme.colors.gray5} 50%, transparent 100%);
            background-size: 200px 100%;
            background-position: -400% 0;
            background-repeat: no-repeat;
            animation: ${loadingAnimation} 1.5s infinite;
        `}
`;

export const ImageLazy = styled.img.attrs(() => ({
    className: "lazyload",
}))`
    // Prevents flash of alt text while loading image
    &.lazyload:not([src]) {
        visibility: hidden;
    }

    object-fit: contain;
`;

export const Image = styled.img`
    object-fit: contain;
`;

import { getConfig } from "@src/config";
import { Maybe } from "@src/graphql.apollo.generated";
import { getCurrencySignFromDomain } from "@src/skeleton/intlProvider/CustomIntlProvider";
import * as React from "react";
import { FormattedNumber } from "react-intl";

interface FormattedCurrencyProps {
    maximumFractionDigits?: number;
    minimumFractionDigits?: number;
    value?: Maybe<number>;
}

const FormattedCurrency: React.FC<FormattedCurrencyProps> = ({ maximumFractionDigits = 0, minimumFractionDigits, value }) => {
    return (
        <>
            {getConfig("scope").domain !== "car4me.porschefinance.hu" && getCurrencySignFromDomain(getConfig("scope").domain)}{" "}
            {value ? (
                <FormattedNumber value={value} maximumFractionDigits={maximumFractionDigits} minimumFractionDigits={minimumFractionDigits} />
            ) : (
                `-`
            )}
            {getConfig("scope").domain === "car4me.porschefinance.hu" && ` ${getCurrencySignFromDomain(getConfig("scope").domain)}`}
        </>
    );
};

export default FormattedCurrency;

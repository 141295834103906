import styled, { css } from "styled-components";

export const Root = styled.div`
    text-align: left;
`;

interface InfoIconTextProp {
    alignment?: "left" | "right" | "center";
    isOpen?: boolean;
}

export const InfoIconText = styled.div<InfoIconTextProp>`
    width: 100%;
    display: block;
    bottom: 0;
    left: 0;
    transition: opacity 0.3s;
    z-index: 10;
    box-sizing: border-box;
    background: ${({ theme }) => theme.colors.backgroundLightGray};
    border-radius: 1px;
    padding: 10px 12px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    box-shadow: 0 0 10px 200vh rgba(0, 0, 0, 0.5);
    position: fixed;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
        display: ${({ isOpen }) => (isOpen ? "block" : "none")};
        box-shadow: none;
        bottom: 33px;
        right: -25px;
        left: auto;
        width: 100vw;
        max-width: 420px;
        transform: none;
        transition: none;
        position: absolute;

        &:after {
            content: "";
            width: 100%;
            height: 0;
            border-top: 14px solid transparent;
            border-bottom: 14px solid transparent;
            border-right: 14px solid ${({ theme }) => theme.colors.backgroundLightGray};
            z-index: 1;
            left: auto;
            right: 40px;
            position: absolute;
            bottom: -16px;
        }

        ${({ alignment }) =>
            alignment === "right" &&
            css`
                left: auto;
                right: 0;

                &:after {
                    right: 16px;
                }
            `}

        ${({ alignment }) =>
            alignment === "left" &&
            css`
                left: auto;
                right: -43vw;

                &:after {
                    left: 30px;
                    border-left: 14px solid ${({ theme }) => theme.colors.backgroundLightGray};
                    border-right: none;
                }
            `}

        ${({ alignment }) =>
            alignment === "center" &&
            css`
                left: auto;
                right: -25vw;

                &:after {
                    left: 28vw;
                    right: 0;
                    border-left: 14px solid ${({ theme }) => theme.colors.backgroundLightGray};
                    border-right: none;
                }
            `}
    }

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        left: -20vw;

        &:after {
            border-right: none;
            border-left: 14px solid ${({ theme }) => theme.colors.backgroundLightGray};
            left: 21vw;
        }

        ${({ alignment }) =>
            alignment === "right" &&
            css`
                left: auto;

                &:after {
                    left: auto;
                    border-right: 14px solid ${({ theme }) => theme.colors.backgroundLightGray};
                    border-left: none;
                }
            `}

        ${({ alignment }) =>
            alignment === "left" &&
            css`
                left: -20px;

                &:after {
                    left: 30px;
                    border-left: 14px solid ${({ theme }) => theme.colors.backgroundLightGray};
                    border-right: none;
                    right: auto;
                }
            `}
    }

    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        left: -1vw;

        &:after {
            left: 25px;
        }

        ${({ alignment }) =>
            alignment === "right" &&
            css`
                left: auto;

                &:after {
                    left: auto;
                    border-right: 14px solid ${({ theme }) => theme.colors.backgroundLightGray};
                    border-left: none;
                }
            `}

        ${({ alignment }) =>
            alignment === "left" &&
            css`
                &:after {
                    left: 34px;
                    border-left: 14px solid ${({ theme }) => theme.colors.backgroundLightGray};
                    border-right: none;
                }
            `}
    }
`;

export const InfoIconContainer = styled.sup`
    padding: 3px 3px 0px 3px;
    display: flex;

    :hover + ${InfoIconText} {
        ${({ theme }) => theme.breakpoints.md.mediaquery} {
            display: block;
            transform: translateY(0);
            box-shadow: ${({ theme }) => theme.boxShadow};
            opacity: 1;
        }
    }
`;

interface InfoIconProps {
    color: "white" | "porschebankBlue";
}

export const InfoIconIcon = styled.span<InfoIconProps>`
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${({ theme, color }) => (color === "white" ? theme.colors.white : theme.colors.porscheBankBlue)};
    color: ${({ theme, color }) => (color === "white" ? theme.colors.porscheBankBlue : theme.colors.white)};
    font-size: 9px;
    line-height: 12px;
    text-align: center;
    align-items: center;
    font-weight: 700;
`;

export const DesktopContainer = styled.div`
    display: none;
    position: relative;
    cursor: pointer;
    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: block;
    }
`;

export const MobileContainer = styled.div`
    display: block;
    position: relative;
    cursor: pointer;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: none;
    }
`;
